import Layout from 'common/layouts/Layout';
import { SEO } from 'components/SEO';
import { Hero } from 'page-components/partnerships/hero';
import { ConsultationModal } from 'page-components/partnerships/consultation-modal';
import { Heading } from 'page-components/partnerships/heading';
import React, { FC } from 'react';
import { PageWrapper } from 'component-library/components/wrappers/PageWrapper';
import { Featured, Footer } from 'page-components/shared';
import { RetirableSteps } from 'page-components/partnerships/retirable-steps';
import { PlanStep } from 'page-components/partnerships/retirable-steps/PlanStep';
import { SpendStep } from 'page-components/partnerships/retirable-steps/SpendStep';
import { SaveStep } from 'page-components/partnerships/retirable-steps/SaveStep';
import { ProtectStep } from 'page-components/partnerships/retirable-steps/ProtectStep';
import { TrustPilot } from 'common/trustpilot/TrustPilot';
import { FooterTemplate } from 'page-components/shared/footer';
import { BottomCta } from 'page-components/partnerships/bottom-cta';
import { InvestmentAndDebitCard } from 'page-components/partnerships/investment-and-debit-card';
import { StaticImage } from 'gatsby-plugin-image';
import { ComeToRightPlaceAdvising } from 'page-components/partnerships/come-to-right-place/ComeToRightPlaceAdvising';
import { promoterVerificationComplianceFooterFor } from 'page-components/partnerships/shared/content';

export interface MarbleProps {}

const TRACKING_PARAMS = {
  source: 'partnerships',
  medium: 'web',
  campaign: 'marble',
  term: '',
  content: '',
};

const MarbleRetirableLogos = (
  <StaticImage
    src="../../page-components/partnerships/images/marble-retirable-logos.webp"
    alt="partnership logo"
    width={512}
  />
);

const Marble: FC<MarbleProps> = () => {
  const [isModalOpen, setIsModalOpen] = React.useState(false);

  const handleConsultationClick = () => {
    setIsModalOpen(true);
  };

  const handleConsultationClose = () => {
    setIsModalOpen(false);
  };

  const childProps = {
    onCtaClick: handleConsultationClick,
    onConsultationClose: handleConsultationClose,
    defaultUtmParams: TRACKING_PARAMS,
  };

  return (
    <Layout>
      <Heading
        {...childProps}
        headerImg={MarbleRetirableLogos}
        ctaButtonText="Meet with a Retirable Advisor"
      />
      <PageWrapper
        background="linear-gradient(
          180deg,
          #EBF8F2 33%,
          #ffe8d8 90%,
          #FFCFAD 100%
        )"
      >
        {isModalOpen && (
          <ConsultationModal
            {...childProps}
            modalImg={MarbleRetirableLogos}
          />
        )}
        <Hero withForm {...childProps} />
        <Featured />
        <RetirableSteps>
          <PlanStep />
          <SpendStep />
          <SaveStep />
          <ProtectStep />
        </RetirableSteps>
        <TrustPilot
          template="fullSizeCarousel"
          heading="We've helped more than 50,000 people just like you."
        />
        <InvestmentAndDebitCard {...childProps} />
        <ComeToRightPlaceAdvising />
        <BottomCta
          {...childProps}
          heading="Get your questions answered by the experts."
          body="Talk to a licensed Retirable Advisor today!"
        />
        <Footer
          template={FooterTemplate.LegalDisclaimerAndContacts}
          additionalFootnotes={[
            promoterVerificationComplianceFooterFor('Marble')
          ]}
          {...childProps}
        />
      </PageWrapper>
    </Layout>
  );
};

export default Marble;

export const Head = () => (
  <SEO
    title="Marble + Retirable"
    description="Learn more about how Marble and Retirable are working together to help you have peace of mind in retirement."
  />
);
